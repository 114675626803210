import "./FixedBanner.css";

export default function FixedBanner() {
  return (
    <>
      <div className="fixed-bg">
        <h2 style={{ color: "white" }}>VIP CUTLERY</h2>
        <p>
          Our company is renowned for the products that we supply to our
          customers. We are determined and dedicated to the work we do and
          ensure that our customers are satisfied with the products and services
          that we provide.
        </p>
      </div>
    </>
  );
}
